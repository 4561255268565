html {
	scroll-behavior: smooth;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kenia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
